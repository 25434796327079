import * as React from 'react';

import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

import {
    CtaProps, GatsbyStrapiPicture
} from '../../../models/cms-common';
import { buildGatsbyStrapiImage } from '../../../utils/cms-utils';
import { isUndOrEmpty } from '../../../utils/string-utils';
import { CtaButton } from '../../shared/cta-button/cta-button.component';
import { TitleBlock } from '../../shared/title-block/title-block.component';
import * as styles from './our-partners-block.module.css';

interface OurPartnersBlockProps extends CtaProps {
    id?: string;
    title?: string;
    description?: string;
    logos: GatsbyStrapiPicture[]
}

export const OurPartnersBlock: React.FC<OurPartnersBlockProps> = ({
    id,
    title,
    description,
    ctaLink,
    ctaText,
    logos
}: OurPartnersBlockProps) => {
    return (
        <React.Fragment>
            <motion.div
                id={id}
                className={styles.opBlock}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.3 }}
                variants={{
                    visible: { color: 'var(--white)' },
                    hidden: { color: 'var(--primary-grey)' }
                }}
            >
                <div className={styles.opTextContainer}>
                    {!isUndOrEmpty(title) && <TitleBlock>{title}</TitleBlock>}
                    {!isUndOrEmpty(description) && (
                        <ReactMarkdown className={styles.description}>{description || ''}</ReactMarkdown>
                    )}
                </div>
                <div className={styles.opLogosContainer}>
                    {logos.map((p, i) => {
                        const img = buildGatsbyStrapiImage(p);
                        return (
                            <motion.div
                                key={i}
                                className={styles.opLogoContainer}
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1 }}
                                transition={{
                                    type: 'linear', duration: 0.5, delay: i / logos.length
                                }}
                            >
                                {img && (
                                    <GatsbyImage className={styles.opLogo} image={img} alt="" objectFit='contain' />
                                )}
                            </motion.div>
                        );
                    })}
                </div>
                {/* <CtaButton link={ctaLink} className={styles.opCtaBtn}>{ctaText}</CtaButton> */}
            </motion.div>
        </React.Fragment>
    );
};
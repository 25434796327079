import * as React from 'react';

import ReactMarkdown from 'react-markdown';

import animationJson from '../../../assets/animations/quesacomotion.json';
import {
    CtaProps,
    SimpleImageBlockProps
} from '../../../models/cms-common';
import { isUndOrEmpty } from '../../../utils/string-utils';
import { CtaButton } from '../../shared/cta-button/cta-button.component';
import { LottieAnimation } from '../../shared/lottie-animation/lottie-animation.component';
import { TitleBlock } from '../../shared/title-block/title-block.component';
import * as styles from './presentation-block.module.css';

interface PresentationBlockOwnProps {
    id?: string;
}

type PresentationBlockProps = PresentationBlockOwnProps & SimpleImageBlockProps & CtaProps;

export const PresentationBlock: React.FC<PresentationBlockProps> = ({
    id,
    title,
    subtitle,
    description,
    ctaLink,
    ctaText
}: PresentationBlockProps) => {
    return (
        <React.Fragment>
            <div id={id} className={styles.presBlock}>
                <LottieAnimation className={styles.presImgContainer} animationJson={animationJson} />
                <div className={styles.presTxtContainer}>
                    {!isUndOrEmpty(title) && <TitleBlock className={styles.titleBlock}>{title}</TitleBlock>}
                    {!isUndOrEmpty(subtitle) && <h2>{subtitle}</h2>}
                    {!isUndOrEmpty(description) && (
                        <ReactMarkdown className={styles.description}>{description}</ReactMarkdown>
                    )}
                    {!isUndOrEmpty(ctaText) && (
                        <div className={styles.presBtnContainer}>
                            <CtaButton link={ctaLink}>{ctaText}</CtaButton>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};
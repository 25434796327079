// extracted by mini-css-extract-plugin
export var intImg = "our-interventions-block-module--intImg--cd645";
export var intImgContainer = "our-interventions-block-module--intImgContainer--d693f";
export var intItem = "our-interventions-block-module--intItem--2d273";
export var intItemTitle = "our-interventions-block-module--intItemTitle--79d09";
export var intItemUpper = "our-interventions-block-module--intItemUpper--1bf1c";
export var intSep = "our-interventions-block-module--intSep--fac81";
export var intTitle = "our-interventions-block-module--intTitle--6269e";
export var intTxtContainer = "our-interventions-block-module--intTxtContainer--be811";
export var intsBlock = "our-interventions-block-module--intsBlock--cb4eb";
export var titleBlock = "our-interventions-block-module--titleBlock--3a54d";
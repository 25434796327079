import * as React from 'react';

import { motion } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';

import animationJson from '../../../assets/animations/landingmotion.json';
import scrollImg from '../../../assets/images/scroll.png';
import { GatsbyStrapiPictureField } from '../../../models/cms-common';
import { LottieAnimation } from '../../shared/lottie-animation/lottie-animation.component';
import * as styles from './slogan-block.module.css';


interface SloganBlockProps {
    title: string;
    subtitle?: string;
    description?: string;
    images: GatsbyStrapiPictureField;
    onScrollIconClick?: () => void;
}

export const SloganBlock: React.FC<SloganBlockProps> = ({
    title, subtitle, description, onScrollIconClick,
}) => {
    return (
        <div className={styles.root}>
            <div className={`
                d-flex flex-column flex-sm-row-reverse
                align-items-center
                ${styles.upper}
            `}>
                <LottieAnimation className={`col-sm-6 ${styles.motionDesign}`} animationJson={animationJson} />
                <div className='
                    col-sm-6 d-flex flex-column
                    align-items-center align-items-sm-start
                '>
                    <span className={styles.name}>{title}</span>
                    {subtitle && <h1 className={styles.title}>{subtitle}</h1>}
                    {description && <h3>{description}</h3>}
                    <div className='mt-3 d-flex justify-content-center justify-content-sm-start'>
                        <div className={styles.msContainer}>
                            <StaticImage
                                className='w-100'
                                src='../../../assets/images/ms_partner.png'
                                alt='Microsoft Partner badge'
                                objectFit='contain'
                            />
                        </div>
                        <div className={styles.gptwContainer}>
                            <StaticImage
                                className='h-100'
                                src='../../../assets/images/great-place-to-work.png'
                                alt='Great place to work badge'
                                objectFit='contain'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <motion.div
                className={`mb-3 ${styles.scrollImg}`}
                onTap={onScrollIconClick}
            >
                <motion.img
                    src={scrollImg}
                    className='h-100 w-100'
                    animate={{ y: -20 }}
                    transition={{
                        ease: 'easeOut',
                        duration: 0.6,
                        repeat: Infinity,
                        repeatType: 'loop'
                    }}
                />
            </motion.div>
        </div>
    );
};
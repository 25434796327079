import * as React from 'react';

import { motion } from 'framer-motion';

import { SimpleImageBlockProps } from '../../../models/cms-common';
import { isUndOrEmpty } from '../../../utils/string-utils';
import { CardItem } from '../../shared/card-item/card-item.component';
import { TitleBlock } from '../../shared/title-block/title-block.component';
import * as styles from './our-interventions-block.module.css';

interface OurInterventionsBlockProps {
    id?: string;
    title?: string;
    description?: string;
    interventions: (SimpleImageBlockProps & {
        accentColor?: string;
    })[];
}

export const OurInterventionsBlock: React.FC<OurInterventionsBlockProps> = ({
    id,
    title,
    interventions
}: OurInterventionsBlockProps) => {
    const variants = {
        hidden: { opacity: 0, },
        visible: { opacity: 1 }
    };
    return (
        <React.Fragment>
            <div id={id} className={`d-flex ${styles.intsBlock}`}>
                {!isUndOrEmpty(title) && <TitleBlock className={styles.titleBlock}>{title}</TitleBlock>}
                <div className='d-flex flex-column flex-lg-row justify-content-center'>
                    {interventions.map((int, index) => {
                        return (
                            <motion.div
                                key={`${int.title}-${index}`}
                                className='col-12 my-3 col-lg-4'
                                variants={variants}
                                initial='hidden'
                                whileInView='visible'
                                viewport={{ amount: 0.8 }}
                                transition={{
                                    type: 'linear', duration: 0.5, delay: 0.1 * (index + 1)
                                }}
                            >
                                <CardItem 
                                    expertise={int}
                                    withAntimation={false}
                                    withCtaLink={false}
                                />
                            </motion.div>
                        );
                    })}
                </div>
            </div>
        </React.Fragment>
    );
};
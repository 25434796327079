import * as React from 'react';

import {
    motion,
    useViewportScroll
} from 'framer-motion';
import {
    StaticQuery,
    graphql
} from 'gatsby';

import { OurInterventionsBlock } from '../components/page-home/our-interventions-block/our-interventions-block.component';
import { OurPartnersBlock } from '../components/page-home/our-partners-block/our-partners-block.component';
import { OurSkillsBlock } from '../components/page-home/our-skills-block/our-skills-block.component';
import { PresentationBlock } from '../components/page-home/presentation-block/presentation-block.component';
import { SloganBlock } from '../components/page-home/slogan-block/slogan-block.component';
import { CustomHeader } from '../components/shared/custom-header/custom-header.component';
import { CustomSection } from '../components/shared/custom-section/custom-section.component';
import {
    CtaProps,
    GatsbyStrapiPictureField,
    SimpleImageBlockProps
} from '../models/cms-common';
import {
    HEADER_HEIGHT_DESKTOP,
    HEADER_HEIGHT_MOBILE
} from '../styles/global-style-constants';
import { isBrowser } from '../utils/url-utils';
import * as styles from './index.module.css';

interface HomePageProps {
    section1Subtitle: string;
    section1Images: GatsbyStrapiPictureField;
    section2Title: string;
    section2Picture: GatsbyStrapiPictureField;
    section2Description: string;
    section2CtaText: string;
    section2CtaLink: string;
    nosSavoirFaireTitle: string;
    nosSavoirFaire: (SimpleImageBlockProps & CtaProps & {
        motionDesign: string;
    })[];
    ourInterventionsTitle?: string;
    ourInterventionsDescription?: string;
    ourInterventions: (SimpleImageBlockProps & {
        accentColor: string;
    })[];
    nosPartenairesTitle: string;
    nosPartenairesDescription: string;
    nosPartenairesLogos: GatsbyStrapiPictureField;
    nosPartnenairesCtaLink?: string;
    nosPartnenairesCtaText?: string;
}

const homePage: React.FC<HomePageProps> = ({
    section1Subtitle,
    section1Images,
    section2Picture,
    section2Title,
    section2Description,
    section2CtaText,
    section2CtaLink,
    nosSavoirFaireTitle,
    nosSavoirFaire,
    ourInterventionsTitle,
    ourInterventionsDescription,
    ourInterventions,
    nosPartenairesTitle,
    nosPartenairesDescription,
    nosPartenairesLogos,
    nosPartnenairesCtaLink,
    nosPartnenairesCtaText
}: HomePageProps) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const { scrollY } = useViewportScroll();
    const [currentBgColor, setCurrentBgColor] = React.useState<string>('#fbfcfa');
    const [darkMode, setDarkMode] = React.useState(true);

    React.useEffect(() => {
        if (isBrowser()) {
            scrollY.onChange((val: any) => {
                const mi = document.getElementById('nos-modes-intervention');
                const np = document.getElementById('nos-partenaires');
                if (mi && np && isBrowser()) {
                    const headerHeight = window.innerWidth > 480 ? HEADER_HEIGHT_DESKTOP : HEADER_HEIGHT_MOBILE;
                    const intervalB = np.offsetTop - (headerHeight);

                    const pageRoot = ref.current;
                    if (pageRoot) {
                        pageRoot.style.backgroundColor = 'var(--white)';
                        if (val > intervalB) {
                            setDarkMode(false);
                        }
                    }
                }
            });
        }

        return () => scrollY.clearListeners();
    }, [scrollY, ref]);

    const onArrowClick = () => {
        if (isBrowser()) {
            const section = document.getElementById('qui-sommes-nous');
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    return (
        <>
            <CustomHeader transparent={false} dark={darkMode} backgroundColor={currentBgColor} />
            <div ref={ref}>
                <motion.section
                    className={styles.sloganBlockContainer}
                    onViewportEnter={() => {
                        setDarkMode(true);
                        setCurrentBgColor('#003366');
                    }}
                    viewport={{ amount: 0.7 }}
                >
                    <SloganBlock
                        title="atawiz"
                        subtitle={section1Subtitle}
                        images={section1Images}
                        onScrollIconClick={onArrowClick}
                    />
                </motion.section>

                <CustomSection
                    className={styles.presentationBlockContainer}
                    onViewportEnter={() => {
                        setDarkMode(false);
                        setCurrentBgColor('#fbfcfa');
                    }}
                    viewport={{ amount: 0.7 }}
                >
                    <PresentationBlock
                        id='qui-sommes-nous'
                        picture={section2Picture}
                        title={section2Title}
                        subtitle=''
                        description={section2Description}
                        ctaLink={section2CtaLink}
                        ctaText={section2CtaText}
                    />
                </CustomSection>

                <CustomSection
                    className={styles.ourSkillsBlockContainer}
                    onViewportEnter={() => {
                        setDarkMode(false);
                        setCurrentBgColor('#e1f8ff');
                    }}
                    viewport={{ amount: 0.7 }}
                >
                    <OurSkillsBlock id='nos-savoir-faire' title={nosSavoirFaireTitle} skills={nosSavoirFaire} />
                </CustomSection>

                <CustomSection
                    className={`d-flex align-items-center justify-content-center w-100 ${styles.ourInterventionsBlock}`}
                    onViewportEnter={() => {
                        setDarkMode(false);
                        setCurrentBgColor('#fbfcfa');
                    }}
                    viewport={{ amount: 0.7 }}
                >
                    <OurInterventionsBlock
                        id='nos-modes-intervention'
                        title={ourInterventionsTitle}
                        description={ourInterventionsDescription}
                        interventions={ourInterventions}
                    />
                </CustomSection>

                <CustomSection
                    id='nos-partenaires'
                    className={styles.ourPartnersBlock}
                    onViewportEnter={() => {
                        setDarkMode(false);
                        setCurrentBgColor('#e1f8ff');
                    }}
                    viewport={{ amount: 0.7 }}
                >
                    <OurPartnersBlock
                        title={nosPartenairesTitle}
                        description={nosPartenairesDescription}
                        ctaLink={nosPartnenairesCtaLink}
                        ctaText={nosPartnenairesCtaText}
                        logos={Array.isArray(nosPartenairesLogos) ? nosPartenairesLogos : []}
                    />
                </CustomSection>
            </div>
        </>
    );
};

const HomePageEnhancer = (Component: React.ComponentType<HomePageProps>) => {
    return (
        <StaticQuery<{ strapiHome: HomePageProps }>
            query={graphql`
				query HomePageQuery {
					strapiHome {
                        section1Subtitle
                        section1Images {
                            localFile {
								childImageSharp {
									gatsbyImageData
								}
							}
                        }

						section2Title
						section2Picture {
							localFile {
								childImageSharp {
									gatsbyImageData
								}
							}
						}
						section2Description
						section2CtaText
						section2CtaLink

						nosSavoirFaireTitle
						nosSavoirFaire {
							description
							subtitle
                            motionDesign
							ctaText
							ctaLink
						}

						ourInterventionsTitle
						ourInterventionsDescription
						ourInterventions {
							picture {
								localFile {
									childImageSharp {
										gatsbyImageData
								  	}
								}
							}
							title
							description
							accentColor
						}

						nosPartenairesTitle
						nosPartenairesDescription
						nosPartenairesLogos {
							localFile {
								childImageSharp {
									gatsbyImageData
								  }
							}
						}
						nosPartnenairesCtaLink
						nosPartnenairesCtaText
					}
				}
				`
            }
            render={data => <Component {...data.strapiHome} />}
        />
    );
};

function HomePage() {
    return HomePageEnhancer(homePage);
}

export default HomePage;

export { Head } from '../components/head/head.component';
import * as React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

import {
    CtaProps,
    SimpleImageBlockProps
} from '../../../models/cms-common';
import { buildGatsbyStrapiImage } from '../../../utils/cms-utils';
import { isUndOrEmpty } from '../../../utils/string-utils';
import { CtaButton } from '../../shared/cta-button/cta-button.component';
import { LottieAnimation } from '../../shared/lottie-animation/lottie-animation.component';
import { TitleBlock } from '../../shared/title-block/title-block.component';
import * as styles from './our-skills-block.module.css';

interface OurSkillsBlockProps {
    id?: string;
    title?: string;
    skills: (SimpleImageBlockProps & CtaProps & {
        motionDesign: string;
    })[];
}

export const OurSkillsBlock: React.FC<OurSkillsBlockProps> = ({
    id,
    title,
    skills
}: OurSkillsBlockProps) => {
    return (
        <React.Fragment>
            <div id={id} className='pb-5 container d-flex flex-column align-items-center justify-content-center'>
                {!isUndOrEmpty(title) && <TitleBlock className={`mt-5 ${styles.titleBlock}`}>{title}</TitleBlock>}
                <div className={styles.skillsContainer}>
                    {skills.map((skill, index) => {
                        const img = buildGatsbyStrapiImage(skill.picture);
                        return (
                            <div
                                key={`${skill.title}-${skill.subtitle}-${index}`}
                                className={`mt-2 row justify-content-between ${styles.skillItem}`}
                            >
                                <div className='col-10 col-lg-5
                                    d-flex flex-column align-items-center justify-content-center'>
                                    {skill.motionDesign && (
                                        <LottieAnimation
                                            className={styles.skillMotionDesign}
                                            animationJson={JSON.parse(skill.motionDesign)}
                                        />
                                    )}
                                    {(!skill.motionDesign || skill.motionDesign === '') && img && (
                                        <GatsbyImage
                                            className='w-100'
                                            image={img}
                                            objectFit='contain'
                                            alt=""
                                        />
                                    )}
                                </div>
                                <div className='
                                    mt-5 mt-lg-0 col-10 col-lg-5
                                    d-flex flex-column align-items-center align-items-md-start'>
                                    {(!isUndOrEmpty(skill.title) || !isUndOrEmpty(skill.subtitle)) && (
                                        <div className={styles.skillItemTitle}>
                                            <h3>{skill.title || skill.subtitle}</h3>
                                            <div className={styles.greenCircle} />
                                        </div>
                                    )}
                                    {!isUndOrEmpty(skill.description) && (
                                        <ReactMarkdown className='mt-2'>{skill.description}</ReactMarkdown>
                                    )}
                                    {!isUndOrEmpty(skill.ctaText) && (
                                        <div className={`mt-2 ${styles.skillBtnContainer}`}>
                                            <CtaButton link={skill.ctaLink}>{skill.ctaText}</CtaButton>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </React.Fragment>
    );
};